// @flow
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import '../../assets/css/Landing.css';

// images
import logo from '../../assets/images/myImages/pIcon.png';

const Footer = () => {
    return (
        <Row className='footy container-fluid d-flex flex-column align-items-center'>
            <Col sm={12} className='text-center'>
             <img src={logo} alt="" className="logo-dark" />
            </Col>
        </Row>
    );
};

export default Footer;
