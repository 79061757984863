import React from 'react';
import Portfolio from './components/Portfolio';

// For Saas import Saas.scss
import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/scss/Saas.scss';


const App = () => {
  return (
    <div>
      <Portfolio />
    </div>
  );
};

export default App;