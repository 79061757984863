import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiHandWaveOutline } from '@mdi/js';

import '../../assets/css/Landing.css';

const AboutMe = () => {
  return (
    <Container sm={12} className='aboutme container-fluid d-flex flex-column align-items-center'>
        <Icon className='mail-brand-icon mb-4'
            path={mdiHandWaveOutline}  
            size={3}
            color="grey">
        </Icon>
        <Row>
            <Col sm={12}>
                <h1>About Me</h1>
            </Col>
            <Col sm={6}>
                <h4 className='aboutme-text'>
                    Hi there! I'm Josh, a Full-Stack Developer with a passion for creating web applications that 
                    are not only functional but also beautiful and user-friendly. I've been working in the tech industry for 3 years, 
                    and I specialize in React.Js, C#, .NET, and SQL, although I'm always eager to learn new things and expand my skill set.
                </h4>
            </Col>
            <Col sm={6}>
                <h4 className='aboutme-text'>
                    When I'm not coding, I love to exercise, draw, and catch up on my books!  I enjoy participating in online communities, attending tech events, 
                    and learning from others. If you have any suggestions or recommendations, please feel free to share them with me!
                </h4>
            </Col>
            <Col sm={6}>
                <h4 className='aboutme-text'>
                    As a developer, I take pride in writing clean, well-organized, and maintainable code that solves complex problems. 
                    I believe that collaboration and communication are essential for building great software, and I enjoy working with other developers, 
                    designers, and stakeholders to create products that exceed our clients' expectations.
                </h4>
            </Col>
            <Col sm={6}>
                <h4 className='aboutme-text'>
                    If you're interested in working together, please don't hesitate to contact me. 
                    I'm always excited about new opportunities and challenges, and I would love to hear from you.
                </h4>
            </Col>
        </Row>
        
    </Container>
  );
};

export default AboutMe;