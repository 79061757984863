import React, { useState } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiDatabase, mdiDotNet, mdiLanguageCsharp, mdiLanguageHtml5, mdiLanguageJavascript, mdiLanguageXaml, mdiMail, mdiMicrosoftVisualStudio, mdiReact } from '@mdi/js';

import '../../assets/css/Landing.css';

const Skills = () => {
  return (
    <Container className='skills container-fluid d-flex justify-content-center'>
        <Row className='container-fluid'>
            <Col sm={12} className='container-fluid d-flex flex-column align-items-center'>
                <Row className='skills-row'>
                    <Col className='skill-icon'>
                        <Icon
                            path={mdiLanguageHtml5}  
                            size={4}
                            color="grey">
                        </Icon>
                        <h5 className='container-fluid d-flex flex-column align-items-center'>HTML5</h5>
                    </Col>
                    <Col className='skill-icon'>
                        <Icon
                            path={mdiReact}  
                            size={4}
                            color="grey">
                        </Icon>
                        <h5 className='container-fluid d-flex flex-column align-items-center'>React.Js</h5>
                    </Col>
                    <Col className='skill-icon'>
                        <Icon
                            path={mdiLanguageJavascript}  
                            size={4}
                            color="grey">
                        </Icon>
                        <h5 className='container-fluid d-flex flex-column align-items-center'>JavaScript</h5>
                    </Col>
                    <Col className='skill-icon'>
                        <Icon
                            path={mdiDotNet}  
                            size={4}
                            color="grey">
                        </Icon>
                        <h5 className='container-fluid d-flex flex-column align-items-center'>.NET/ASP.NET</h5>
                    </Col>
                    <Col className='skill-icon'>
                        <Icon
                            path={mdiLanguageCsharp}  
                            size={4}
                            color="grey">
                        </Icon>
                        <h5 className='container-fluid d-flex flex-column align-items-center'>C Sharp</h5>

                    </Col>
                    <Col className='skill-icon'>
                        <Icon
                            path={mdiDatabase}  
                            size={4}
                            color="grey">
                        </Icon>
                        <h5 className='container-fluid d-flex flex-column align-items-center'>SQL</h5>
                    </Col>
                    <Col className='skill-icon'>
                        <Icon
                            path={mdiMicrosoftVisualStudio}  
                            size={4}
                            color="grey">
                        </Icon>
                        <h5 className=''>Visual Studio</h5>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
  );
};

export default Skills;