import React from 'react';
import { Col, Row, Card, Container, ListGroup } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiCodeTags, mdiGithub } from '@mdi/js';
import TektonGif from '../../assets/images/myImages/tekton.gif'
import TulipMaidsGif from '../../assets/images/myImages/tulipmaids.gif'

import '../../assets/css/Landing.css';

const Work = () => {
  return (
    <Container sm={12} className='work container-fluid d-flex flex-column align-items-center'>
        <Icon className='code-tag-icon mb-4'
            path={mdiCodeTags}  
            size={3}
            color="grey">
        </Icon>
        <Row className='mb-3'>
            <Col sm={12} className='mb-3'>
                <h1 className='text-center'>Professional Work</h1>
            </Col>
            <Col sm={6}>
                <Card className='work-cards p-3 m-2'>
                    <Card.Img variant="top" src={TektonGif} />
                    <Card.Body className='work-cards-body'>
                        <Card.Title>Tekton Construction Technologies - Full Stack Software Developer</Card.Title>
                        <Card.Text>
                            Tekton Construction Technologies is a cloud-based organization document management solution focused on streamlining workforce management in the construction space.
                        </Card.Text>
                        <Card.Title>Duties</Card.Title>
                        <Card.Text>
                            Worked as a Full Stack Software Engineer to develop an MVP for a client and help build an web API using C#/.NET. 
                            I built out React Components for multiple pages and developed neat and robust code. I also helped update our backend in SQL 
                            to conform to new changes in the API by building out new tables, diagrams, and stored procedures. Some of the features I worked on were: developing our
                            organizations route and profiles for each organization as well as developing and connecting our contact form, front-end and backend.
                        </Card.Text>
                            <a href='https://github.com/joshflores/Tekton-Construction-Tech' target="_blank" rel='noreferrer'>
                                <Icon className='m-3'
                                    path={mdiGithub}
                                    size={1.5}
                                    color="grey">
                                </Icon> 
                            </a>
                        <ListGroup horizontal className="mb-3 work-listgroup">
                            <ListGroup.Item>React.Js</ListGroup.Item>
                            <ListGroup.Item>C#</ListGroup.Item>
                            <ListGroup.Item>.NET</ListGroup.Item>
                        </ListGroup>
                        <ListGroup horizontal className="mb-3 work-listgroup">
                            <ListGroup.Item>SQL</ListGroup.Item>
                            <ListGroup.Item>SendGrid</ListGroup.Item>
                            <ListGroup.Item>Google Maps</ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={6}>
                <Card className=' work-cards p-3 m-2'>
                    <Card.Img variant="top" src={TulipMaidsGif} />
                    <Card.Body className='work-cards-body'>
                        <Card.Title>Tulip Maids - Software Engineer</Card.Title>
                        <Card.Text>
                            Tulip Maids is a house cleaning service that makes it easy to schedule an appointment anytime in the Los Angeles Area.
                        </Card.Text>
                        <Card.Title>Duties</Card.Title>
                        <Card.Text>
                            As a Full Stack Software Engineer, I was responsible for developing a basic website using HTML, CSS, and JavaScript and then expanding it into a full-stack application. 
                            This involved developing a server-side component and integrating it with a database. Throughout the project, I gained hands-on experience with various front-end 
                            and back-end technologies, including React, C#/.NET Core 6, and SQL. Some of the features I worked on were: Developing the Calendar and integrating Stripe.Js, front-end and backend.
                        </Card.Text>
                        <div>
                            <ListGroup horizontal className="mb-3 work-listgroup">
                                <ListGroup.Item>React.Js</ListGroup.Item>
                                <ListGroup.Item>C#</ListGroup.Item>
                                <ListGroup.Item>.NET</ListGroup.Item>
                            </ListGroup>
                            <ListGroup horizontal className="mb-3 work-listgroup">
                                <ListGroup.Item>SQL</ListGroup.Item>
                                <ListGroup.Item>Stripe.Js</ListGroup.Item>
                            </ListGroup>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
  );
};

export default Work;