import React, { useState } from 'react';
import { Nav, Navbar, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiMail } from '@mdi/js';
import '../../assets/css/Navbar.css';

const NavBar = () => {
    const [isCopied, setIsCopied] = useState(false);
    const email = 'joshflor07@gmail.com';

    const handleCopy = () => {
        navigator.clipboard.writeText(email);
        setIsCopied(true);
    };

    const renderTooltip = (text) => (
        <Tooltip id="button-tooltip">
            {text}
        </Tooltip>
    );

    const downloadResume = () => {
        const link = document.createElement('a');
        link.href = '/files/Joshua Flores - Software Engineer.pdf';
        link.download = 'Joshua Flores - Software Engineer.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" variant="light" className="py-lg-3">
                <Container>
                    <div className='col-md-3'>
                        <Navbar.Brand href="mailto:Joshflor07@gmail.com" className="me-lg-2 mail-icon-landing-page">
                            <Icon className='mail-brand-icon'
                                path={mdiMail}  
                                title="Send Email" 
                                size={1.5}
                                color="grey">
                            </Icon>
                        </Navbar.Brand>
                        
                        <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(isCopied ? 'Copied!' : 'Copy')}
                        >
                            <span onClick={handleCopy} className="email-landing-page">
                                {email}
                            </span>
                        </OverlayTrigger>                
                    </div>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav">
                        <i className="mdi mdi-menu"></i>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
                        <Nav as="ul">
                            <Nav.Item className="mx-lg-1">
                                <Nav.Link href="https://www.linkedin.com/in/josh-flores/">LinkedIn</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="mx-lg-1">
                                <Nav.Link href="https://github.com/joshflores">Github</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="mx-lg-1">
                                <Nav.Link href="">Blog</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="mx-lg-1">
                                <Nav.Link onClick={downloadResume}>Resume</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default NavBar;
