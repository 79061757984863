import React from 'react';
import { Container, Col} from 'react-bootstrap';

import Skills from './skills';
import Landing from './landing';
import AboutMe from './aboutme';
import Work from './work';
import Contact from './Contact';
import Footer from '../footer/footer';
import WorkApi from './workapi';

import '../../assets/css/Landing.css';

const index = () => {
  return (
        <Col className=' container-fluid' sm={12}>
            <Landing/>

            <Skills/>

            <AboutMe/>

            <Work />

            <WorkApi />

            {/* <Contact /> */}

            <Footer />
        </Col>

  );
};

export default index;