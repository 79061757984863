import React from 'react';
import NavBar from './navbar/NavBar';
import Landing from './landing/Index';

const Portfolio = () => {
  return (
    <div>
      <NavBar />
      <Landing />
    </div>
  );
};

export default Portfolio;