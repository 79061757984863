import React from 'react';
import { Col } from 'react-bootstrap';

import profilePic from '../../assets/images/myImages/image.jpg';
import '../../assets/css/Landing.css';

const landing = () => {
  return (
    <Col sm={12} className='landing container-fluid d-flex flex-column align-items-center'>
        <img src={profilePic} alt="" className="img-fluid avatar-xl rounded-circle img-thumbnail profile-picture"/>
        <h3 className=''>Hi, I'm Joshua Flores!</h3>
        <h4>Building Digital Products, Brands, and Experiences as a Full Stack Developer.</h4>
    </Col>
  );
};

export default landing;