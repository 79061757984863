import React, { useState } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiAws, mdiDatabase, mdiDotNet, mdiGoogle, mdiGoogleMaps, mdiLanguageCsharp, mdiLanguageHtml5, mdiLanguageJavascript, mdiLanguageXaml, mdiMail, mdiMicrosoftVisualStudio, mdiReact } from '@mdi/js';

import '../../assets/css/Landing.css';
import SendGrid from "../../assets/images/myImages/sendgrid.svg"
import GoogleMaps from "../../assets/images/myImages/googlemaps.svg"
import Google from "../../assets/images/myImages/google.svg"
import Aws from "../../assets/images/myImages/aws.svg"

const WorkApi = () => {
  return (
    <Container className='workapi container-fluid d-flex justify-content-center'>
        <Row className='container-fluid'>
            <Col sm={12} className='container-fluid d-flex flex-column justify-items-center text-center'>
                <h2 className='mb-5'>Some Api's I've Worked with</h2>
                <Row className='skills-row'>
                    <Col className='skill-icon'>
                        <img src={Aws} alt="" className="img-fluid avatar-md"/>
                        <h5 className=''>AWS</h5>
                    </Col>
                    <Col className='skill-icon'>
                        <img src={Google} alt="" className="img-fluid avatar-md"/>
                        <h5 className=''>Google API</h5>
                    </Col>
                    <Col className='skill-icon'>
                        <img src={GoogleMaps} alt="" className="img-fluid avatar-md"/>
                        <h5 className=''>Google Maps</h5>
                    </Col>
                    <Col className='skill-icon'>
                        <img src={SendGrid} alt="" className="img-fluid avatar-md"/>
                        <h5 className=''>SendGrid</h5>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
  );
};

export default WorkApi;